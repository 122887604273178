<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.orderNo"
        placeholder="系统支付单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.platformOrderNo"
        placeholder="支付平台单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.platform"
        placeholder="支付平台"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in PLATFORM"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.typeId"
        placeholder="设备类别"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in equipmentOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-input
        v-model="listQuery.devicesOrderNum"
        placeholder="设备服务单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.devicesNum"
        placeholder="设备编号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.userkw"
        placeholder="用户关键字(手机号码，姓名，微信昵称)"
        style="width: 250px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.storeId"
        placeholder="门店"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in storeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <CascadeRegion
        :isRequired="true"
        :value="listQuery.cascadeRegion"
        @valueFunc="getQueryValueFunc"
        type="cascadeRegion"
        :styleRef="{ width: '210px', marginRight: '6px', top: '-4px' }"
      />
      <el-select
        v-model="listQuery.status"
        placeholder="支付状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in PAYMENT_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">设备支付流水数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="订单编号"
        width="220"
        align="center"
        prop="order_num"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="trade_num"
        label="支付平台流水号"
        width="300px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platform"
        label="支付平台"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ getValue(PAY_METHOD, scope.row.platform) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="金额"
        width="120"
        align="center"
        prop="amount"
      ></el-table-column>
      <el-table-column label="状态" width="120" align="center" prop="status">
        <template slot-scope="scope">
          <span>{{ getValue(PAYMENT_STATUS, scope.row.status) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="pay_date"
        label="支付时间"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ formatApiDate(scope.row.pay_date) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="wx_name"
        label="用户昵称"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.user.wx_name }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="mobile"
        label="用户手机号码"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.user.mobile }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="checkApplyRefundVisible(true, scope.row)"
            >申请退款</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    申请退款-->
    <el-dialog
      title="申请退款"
      :visible.sync="applyRefundVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="退款金额" prop="amount">
          <el-input v-model="form.amount" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="退款原因" prop="remark">
          <el-input type="te" v-model="form.remark" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkApplyRefundVisible(false, {})">取消</el-button>
        <el-button
          type="primary"
          @click="refundApplyOk(false)"
          :loading="refundApplyLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { formatApiDate } from "@/utils/utils";
import {
  getValue,
  PAY_METHOD,
  PAYMENT_STATUS,
  PLATFORM
} from "@/utils/enumUtile";
import { refundApply, getPayOrderList } from "@/api/financial";
import CascadeRegion from "@/views/components/cascadeRegion.vue";
import { getStoreList } from "@/api/store";
import { getEquipmentList } from "@/api/equipment";

export default {
  computed: {
    PAYMENT_STATUS() {
      return PAYMENT_STATUS;
    },
    PLATFORM() {
      return PLATFORM;
    },
    PAY_METHOD() {
      return PAY_METHOD;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: { CascadeRegion },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        orderNo: "", // 系统支付订单号
        platformOrderNo: "", // 平台支付订单号
        platform: "", //支付平台：1-微信，2-支付宝，3-澳门积分，4-系统积分
        typeId: "", //设备类别ID
        devicesOrderNum: "", //设备服务单号
        devicesNum: "", //设备编号
        userkw: "", //用户关键字(手机号码，姓名，微信昵称)
        storeId: "", //门店
        cascadeRegion: [],
        provinceId: "",
        cityId: "",
        areaId: "",
        streetId: "",
        status: "" //状态:1-待支付，2-支付中，3-已支付，4-已作废
      },
      applyRefundVisible: false,
      refundApplyLoading: false,
      rules: {
        amount: [
          { required: true, message: "退款金额不能为空", trigger: "change" }
        ], // 退款金额（小于等于支付单金额）
        remark: [
          { required: true, message: "退款原因不能为空", trigger: "change" }
        ] //退款原因
      },
      form: {
        id: 0,
        amount: "", // 退款金额
        remark: "" //退款原因
      },
      storeOption: [], // 门店选项
      equipmentOption: [] // 设备类别选项
    };
  },
  created() {
    this.getList();
    this.getStoreList();
    this.getEquipmentList();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getPayOrderList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 查询门店
     */
    getStoreList() {
      const params = {
        page: 1,
        limit: 9999
      };
      getStoreList(params).then(resp => {
        this.storeOption = resp.data.data;
      });
    },
    /**
     * 查询设备类别
     */
    getEquipmentList() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        this.equipmentOption = resp.data.data;
      });
    },

    /**
     * 申请退款
     * @param status
     * @param editObj
     */
    checkApplyRefundVisible(status, editObj = {}) {
      this.applyRefundVisible = status;
      this.form.id = editObj.id;
    },
    /**
     * 申请退款
     */
    refundApplyOk() {
      this.refundApplyLoading = true;
      const params = this.form;
      refundApply(params)
        .then(resp => {
          console.log("resp", resp);
          if (resp.code === 0) {
            this.$notify({
              title: "提示",
              message: resp.data,
              type: "success",
              duration: 2000
            });
            this.checkApplyRefundVisible(false, {});
          } else {
            this.$notify({
              title: "提示",
              message: resp.data,
              type: "warning",
              duration: 2000
            });
          }
        })
        .finally(() => {
          this.refundApplyLoading = false;
        });
    },
    /**
     * 省市区街道的值获取
     * @param type
     * @param resultArr
     */
    getQueryValueFunc(type, resultArr) {
      if (type === "cascadeRegion") {
        this.listQuery.cascadeRegion = resultArr;
        if (resultArr && resultArr.length !== 0) {
          this.listQuery.provinceId = resultArr[0];
          this.listQuery.cityId = resultArr[1];
          this.listQuery.areaId = resultArr[2];
          this.listQuery.streetId = resultArr[3];
        } else {
          this.listQuery.provinceId = "";
          this.listQuery.cityId = "";
          this.listQuery.areaId = "";
          this.listQuery.streetId = "";
        }
      }
    }
  }
};
</script>
<style scoped lang="scss"></style>
